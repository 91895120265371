<!--
 * @Author: xr
 * @Date: 2022-03-10 09:35:51
 * @LastEditors: xr
 * @LastEditTime: 2022-03-10 11:08:33
 * @FilePath: \postStation-office\src\views\famousEnterprise.vue
-->
<template>
  <div class="main">
    <div class="main_box">
      <a-row type="flex" :gutter="20" justify="space-between" style="padding:0px 10px 10px;">
        <a-col :span="7" style="padding-left: 0px">
          <find-demand class="find-item" title="找项目" :technicalProjectContent="technicalProjectContent" />
          <find-demand class="find-demand" title="找需求" :technicalProjectContent="technicalProjectContent"/>
        </a-col>
        <a-col :span="17" style="min-height:62vh">
          <a-row style="padding-top:10px" >

            <article class="introduction sub-component-background-color">

<!--              <strong>-->
<!--                <h3 style="margin-top: 10px;">企业简介</h3>-->
<!--               </strong>-->
              <div class="company" style="min-height: 520px">
                <a-row :gutter="20" justify="center" type="flex">
                  <a-col :span="6">
                    <img
:src="information.enterprisePicture"
@click="handlePreview(information.enterprisePicture)"
                         style="width: 160px;margin: 10px 16px ;box-shadow: 0px 0px 5px #ddd;">
                  </a-col>
                  <a-col  :span="18">
                    <div style="margin-top:20px; text-align: center; ">
                      <div><span style="font-weight: 600;font-size: 22px">{{information.enterpriseName}}</span></div>
                      <div><span style="color: rgb(128, 128, 128);font-size: 12px">{{information.entryTime||'暂无时间'}}</span></div>
                    </div>
                  </a-col>
                  <a-col :span="24" >
                    <div style="border-top:1px solid #dddddd;margin: 0 20px;" >
                      <a-empty v-if="information.enterpriseProfile==null" class="empty-data"/>
                      <div v-if="information.enterpriseProfile">
                        <div v-html="information.enterpriseProfile" />
                      </div>

                    </div>

                  </a-col>
                </a-row>

              </div>
<!--              <strong><h3 style="margin-top: 20px;">企业图片</h3></strong>-->
<!--              <div class="company">-->
<!--                <a-row >-->
<!--                  <a-col span="6">-->
<!--                   <img :src="information.enterprisePicture" style="height: 180px;margin: 10px 16px;box-shadow: 0px 0px 5px #ddd;">-->
<!--                  </a-col>-->
<!--                </a-row>-->
<!--              </div>-->
              <a-empty v-if="information===''" class="empty-data"/>
            </article>
          </a-row>
        </a-col>
      </a-row>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" >
      </a-modal>
      <!-- 友情链接 -->
      <link-box />
    </div>
  </div>
</template>

<script>
import linkBox from '@comp/layout/link'
import FindDemand from '@comp/findDemand'
import { getDict } from '@/api/index'
export default {
  name: 'FamousEnterprise',
  components: {
    linkBox,
    FindDemand
  },
  data() {
    return {
      technicalProjectContent: [],
      information: { },
      previewVisible: false,
      previewImage: ''
    }
  },

  activated() {
    getDict('application_field').then(res => {
      this.technicalProjectContent = res.result
    })
    // 刷新
    if (this.$route.params.listData) {
      // 接收传过来的值
      this.information = this.$route.params.listData
      sessionStorage.setItem('enterpriseInformation', JSON.stringify(this.$route.params.listData))
    } else {
      this.$nextTick(() => {
        this.information = JSON.parse(sessionStorage.getItem('enterpriseInformation'))
      })
    }
  },
  methods: {
    handleCancel() {
      this.previewVisible = false
    },
    handlePreview(file) {
      this.previewImage = file
      this.previewVisible = true
    }
  }

}
</script>
<style lang="scss" scoped>
.introduction{
  &>p{
    font-family: '宋体';
    font-size: 16px;
  }
  h3{
    margin: -15px 0px -8px 0px;
    font-weight: bold;
    font-size: 20px;
    font-family: '宋体';
  }
  .text{
    text-indent: 24px;
  }
  .company{
    border: 1px solid #dddddd;
    border-radius: 5px;
    min-height: 150px;
    margin: 15px 0px;
    //box-shadow: 0px 0px 5px #ddd;
  }
}
@import "../assets/css/newsInformation.css";
</style>
